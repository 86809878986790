// copied the code from john hancock gem forked by Roman
// on the gem code was inside DOMContentLoaded which made the code to not work in some cases
document.addEventListener('turbolinks:load', function () {
    const allCanvas = document.querySelectorAll(".JohnHancock-canvas");

    allCanvas.forEach((canvas) => {
        let wrapper = canvas.closest('.JohnHancock-wrapper');
        let hidden_field = wrapper.querySelector(".JohnHancock-hidden");
        let clear_canvas = wrapper.querySelector(".clear-canvas");

        if (hidden_field) {
            const parent_form = canvas.closest("form");
            const signaturePad = new SignaturePad(canvas);

            // Load the existing signature, if available
            const savedSignature = hidden_field.value; // Retrieve the base64 string from the hidden field

            if (savedSignature) {
                var image = new Image();
                image.src = savedSignature;
                image.onload = function () {
                    // Draw the saved signature on the canvas
                    canvas.getContext("2d").drawImage(image, 0, 0);
                };
            }

            parent_form.addEventListener('submit', function () {
                hidden_field.value = signaturePad.toDataURL()
            });

            var width = $(window).width(), height = $(window).height();

            function resizeCanvas() {
                if($(window).width() != width || $(window).height() != height) {
                    canvas.width = 500
                    canvas.height = 300
                }
                // var ratio = Math.max(window.devicePixelRatio || 1, 1);
                // canvas.width = canvas.offsetWidth * ratio;
                // canvas.height = canvas.offsetHeight * ratio;
                // canvas.getContext("2d").scale(ratio, ratio);
                // signaturePad.clear();
            }

            window.addEventListener("resize", resizeCanvas, true);
            resizeCanvas();
        }

        clear_canvas?.addEventListener('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var canvas = wrapper.querySelector(".JohnHancock-canvas");
            var context = canvas.getContext("2d");
            context.clearRect(0, 0, canvas.width, canvas.height);
        });
    });
});