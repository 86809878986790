document.addEventListener('turbolinks:load', function () {
    if ($('.project_folders.show').length === 0) {
        return false
    }

    // Disable and enable download and delete button based on checkboxes checked
    $('.checkbox').on('change', function (){
        toggleDownloadDeleteButtons();
    });

    // Select files according to their file type (CLient/Review Documents)
    $('.file-type .select-all').on('change', function () {
        let fileType = $(this).data('fileType')
        if ($(this).prop('checked') === true) {
            $('.' + fileType).prop('checked', true);
        } else {
            $('.' + fileType).prop('checked', false);
        }
        toggleDownloadDeleteButtons();
    });

    function toggleDownloadDeleteButtons(){
        if ($('.checkbox:checked').length > 0) {
            $('.delete_project_file').removeClass('no-visibility')
            $('.download_selected_file').removeClass('no-visibility')
            $('.modifyFileDropdown').removeClass('d-none')
            $('.modifyFileDropdown').addClass('d-inline')
        } else {
            $('.delete_project_file').addClass('no-visibility')
            $('.download_selected_file').addClass('no-visibility')
            $('.modifyFileDropdown').addClass('d-none')
            $('.modifyFileDropdown').removeClass('d-inline')
        }
    }

    // Download all selected files in checkbox
    $('.download_selected_file').on('click', function (){
        $('.fileIdsForm').submit();
    });
});